$fontMainPage: "/fonts/Open Sans.css";
$fontSidebar:  "Open Sans";
$fontNavbar:   "Open Sans";
$mainColour:   #F39200;








$loadingImage: "/loader.svg";

@import "bulma/bulma";
@import "fresh/core";
