/* ==========================================================================
Color variables
========================================================================== */

$white: #fff;
$smoke-white: #fcfcfc;
$grey-white: #f2f2f2;

$primary: #4FC1EA;
$secondary: $mainColour; //#ba00f3 // #F39200
$accent: #00efb7;

$fade-grey: #ededed;
$light-grey: #EFF4F7;
$title-grey: #A9ABAC;
$blue-grey: #444F60;
$muted-grey: #999;
$light-blue-grey: #98a9c3;
$medium-grey: #66676b;
$basaltic-grey: #878787;
$purple: #7F00FF;
$mint: #11FFBD;
$bloody: #FC354C;
$pinky: #ff00cc;
$frost: #004e92; //#004e92
$placeholder: #cecece;
$dark-grey: #344258;
$border-grey: #ccc;
$muted-grey: #999;
$section-grey: #fbfbfb;